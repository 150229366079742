import { React, Image, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import iso1 from './img/303742.jpg'
import iso2 from './img/303741.jpg'
import iso3 from './img/303743.jpg'
import vision from './img/vision.jpg'
import mission from './img/mission.jpg'
import officeaddress from './img/303391.jpg'

const languages = [
  {
    code: 'en',
    name: 'english',
    country_code: 'us',
  },
  {
    code: 'fr',
    name: 'chinese',
    country_code: 'hk',
  }
]

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
)

export default function App() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  console.log(`Default language = ${currentLanguageCode}`)

  const releaseDate = new Date('2021-03-07')
  const timeDifference = new Date() - releaseDate
  const number_of_days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  useEffect(() => {
    console.log(`Setting page stuff`)
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div>
      <nav class="navbar navbar-expand-lg bg-dark navbar-dark py-3 fixed-top">
        <div class="container">
          <a href="#" class="navbar-brand">{t('app_title')}</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu">
            <span class="navbar-toggler-icon"></span>
          </button>


          <div class="collapse navbar-collapse" id="navmenu">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <a href="#vision" class="nav-link">{t('vision')}</a>
              </li>
              <li class="nav-item">
                <a href="#mission" class="nav-link">{t('mission')}</a>
              </li>
              <li class="nav-item">
                <a href="#aboutus" class="nav-link">{t('aboutus')}</a>
              </li>
              <li class="nav-item">
                <div className="language-select">
                  <div className="d-flex justify-content-end align-items-center language-select-root">
                    <div className="dropdown">
                      <button
                        className="btn btn-link dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <GlobeIcon />
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                          <span className="dropdown-item-text">{t('language')}</span>
                        </li>
                        {languages.map(({ code, name, country_code }) => (
                          <li key={country_code}>
                            <a
                              href="#"
                              className={classNames('dropdown-item', {
                                disabled: currentLanguageCode === code,
                              })}
                              onClick={() => {
                                i18next.changeLanguage(code)
                              }}
                            >
                              <span
                                className={`flag-icon flag-icon-${country_code} mx-2`}
                                style={{
                                  opacity: currentLanguageCode === code ? 0.5 : 1,
                                }}
                              ></span>
                              {t(`${name}`)}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div style={{marginTop:60}}>
        <section class="bg-dark text-light p-5 p-lg-0 pt-lg-5 text-center text-sm-start">
          <div class="container">
            <div class="d-sm-flex align-items-center justify-content-between">
              <div>
                <h1><span class="text-warning">Business Scope</span></h1>
                <p class="lead my-4">
                Primega Construction Engineering Co. Limited 鈞御工程有限公司 (the Company) is wholly owned by PRIMEGA GROUP HOLDINGS LTD (the Group). The Group is located at Room 2912, New Tech Plaza, No. 34 Tai Yau Street, San Po Kong, Kowloon, Hong Kong.
                </p>
                <p class="lead my-4">
                The Company is found by Mr. Man Siu Ming who has over 15 years experience in construction industry particularly in soil & rock treatment. The main business scope includes environmental protection transportation, construction works and selling approved high-quality biodiesel for construction contractors.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="p-5" >
        <div class="container">
            <div class="row text-center g-5">
                <h2 class="text-center mb-4">
                    Quality Awards
                </h2>              
                <div class="col-md">
                    <div >
                        <div class="card-body text-center">                      
                          <img src={iso1} width='100%' alt=""></img>                          
                          <h3 class="card-title mb-3">
                            ISO9001:2015
                          </h3>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div >
                        <div class="card-body text-center">
                            <img src={iso2} width='100%' alt=""></img>
                            <h3 class="card-title mb-3">
                              ISO14001:2015
                            </h3>
                        </div>
                    </div>
                </div>                
                <div class="col-md">
                    <div >
                        <div class="card-body text-center">
                            <img src={iso3} width="100%" alt=""/>
                            <h3 class="card-title mb-3">
                              ISO45001:2015
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="vision" class="p-5">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md">
                <img src={vision} class="img-fluid" alt=""></img>
                </div>
                <div class="col-md pt-5">
                    <h2>{t('vision')}</h2>
                    <p class="lead">
                      The company has a good reputation in the industry and long-term cooperative relationships with upstream and downstream enterprises. We have close cooperation with many large local construction engineering companies and contractors.
                    </p>
                    <p class="lead">
                      The competition within the industry is extremely keen, and the project deadline is very tight. One of the main considerations is to complete the project on schedule. In order to catch up with the progress, construction projects often produce lots of construction and engineering waste; these consumables also account for a large portion of construction project costs.</p>
                    <p class="lead">
                      In such a traditional industry, as the construction industry, new business opportunities abound. With the development of the times, more and more attention has been paid to creating sustainable renewable resources.</p>
                </div>
            </div>
        </div>
    </section>    
    <section id="mission" class="p-5 bg-dark text-light">
        <div class="container"> 
            <div class="row align-items-center justify-content-between">
                <div class="col-md pt-5">
                    <h2>{t('mission')}</h2>
                    <p class="lead">
                      Mr. Man deeply feels that investing in sustainable energy and creating sustainable green returns will bring a revolutionary development to the construction industry.
                    </p>
                    <p class="lead">
                      This not only enables construction companies to reduce engineering costs, but also promotes construction companies to comply with the environmental management system policies initiated by the government, bringing immediate and visible benefits to construction companies. At the same time, it will stimulate the development of the environmental protection in the industry and open up the entire environmental protection industry chain.</p>
                    <p class="lead">
                      This kind of win-win business operation will definitely be accepted by the industry and setting off a "green new wind" in the industry. Therefore, Mr. Man determined to establish the Company in 2018 to practice the concept of ESG. We wishing through our attempts, under the limited resource environment, the construction industry can also directly follow the direction of the development of the times, and realize "environmental protection and reuse" in the construction industry.</p>
                </div>            
                <div class="col-md">
                <img src={mission} class="img-fluid" alt=""></img>
                </div>                  
            </div>
        </div>
    </section>
    <section id='aboutus' class="p-5">
        <div class="container">
            <div class="row g-4">
                <div class="col-md-7">
                    <h2 class="text-center mb-4">{t('aboutus')}</h2>
                    <ul class="list-group list-group-flush lead">
                        <li class="list-group-item">
                            <span class="fw-bold">{t('addressT')} : </span>{t('address')}
                          </li>
                          <li class="list-group-item">
                            <span class="fw-bold">{t('officetelT')} : </span> {t('officetel')} 
                          </li>
{/*                          <li class="list-group-item">
                            <span class="fw-bold">{t('faxT')} : </span> {t('fax')} 
                              </li>*/}
                          <li class="list-group-item">
                            <span class="fw-bold">{t('emailT')} : </span> <a href={"mailto:" + t('email')} >{t('email')} </a>
                          </li>
                          <li class="list-group-item">
                            <span class="fw-bold">{t('mtrT')} : </span> {t('mtr')} 
                          </li>
                    </ul>
                </div>
                <div class="col-md-5">
                    <img src={officeaddress} width="100%" alt=''/>
                </div>
            </div>
        </div>
    </section>
    </div>
    <footer class="bg-dark text-white text-center py-3 position-relative">
        <div class="container">
            <p class="lead">Copyright &copy; 2024 Primega Construction Engineering Co. Limited</p>
            <a href="#" class="position-absolute bottom-0 end-0 p-5"><i class="bi bi-arrow-up-circle h1"></i></a>
        </div>
    </footer>
    </div>
  )
}

const styles = {
  "container": {    
    margin: "60 0 0 0",
  }
}
